import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  accessToken: string | null = null;
  refreshToken: string | null = null;
  decodedAccessToken: { [key: string]: string } | null = null;
  decodedRefreshToken: { [key: string]: string } | null = null;

  constructor(private localStorageService: LocalStorageService) { 
    this.setAccessToken(localStorageService.get('accessToken'));
    this.setRefreshToken(localStorageService.get('refreshToken'));
  }

  canActivate() {
    if (this.getAccessToken()) {
      return true;
    } else {
      return false;
    }
  }

  setAccessToken(token?: string | null): void {
    if (token) {
      this.accessToken = token;
      this.localStorageService.set('accessToken', this.accessToken);
      this.decodedAccessToken = jwtDecode(this.accessToken);
    }
  }

  removeAccessToken(): void {
      this.accessToken = null;
      this.localStorageService.remove('accessToken');
      this.decodedAccessToken = null;
  }

  getAccessToken(): string | null {
    return this.accessToken;
  }

  getDecodedAccessToken(): { [key: string]: string } | null {
    return this.decodedAccessToken;
  }

  getUserId(): string | null {
    if (this.decodedAccessToken) {
      return this.decodedAccessToken ? this.decodedAccessToken['sub'] : null;
    } else {
      return null;
    }
  }

  getUserRole(): string | null {
    if (this.decodedAccessToken) {
      return this.decodedAccessToken ? this.decodedAccessToken['role'] : null;
    } else {
      return null;
    }
  }

  getAccessTokenExpiryTime(): number {
    if (this.decodedAccessToken) {
      return this.decodedAccessToken ? parseInt(this.decodedAccessToken['exp']) : 0;
    } else {
      return 0;
    }
  }

  isAccessTokenExpired(): boolean {
    const expiryTime: number = this.getAccessTokenExpiryTime();
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date().getTime()) < 5000);
    } else {
      return false;
    }
  }

  setRefreshToken(token?: string | null) {
    if (token) {
      this.refreshToken = token;
      this.localStorageService.set('refreshToken', this.refreshToken);
      this.decodedRefreshToken = jwtDecode(this.refreshToken);
    }
  }

  removeRefreshToken(): void {
    this.refreshToken = null;
    this.localStorageService.remove('refreshToken');
    this.decodedRefreshToken = null;
  }

  getRefreshToken(): string | null {
    return this.refreshToken;
  }

  getRefreshTokenExpiryTime(): number {
    if (this.decodedRefreshToken) {
      return this.decodedAccessToken ? parseInt(this.decodedRefreshToken['exp']) : 0;
    } else {
      return 0;
    }
  }

  isRefreshTokenExpired(): boolean {
    const expiryTime: number = this.getRefreshTokenExpiryTime();
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date().getTime()) < 5000);
    } else {
      return false;
    }
  }

}
